
import { Component, Prop, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
import SendVerificationForm from "@/components/forms/SendVerificationForm.vue";

@Component({
  components: {
    FormCard,
    LogoSingle,
    SendVerificationForm
  }
})
export default class SendVerification extends Vue {}
